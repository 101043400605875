import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';
import { BlueBox } from 'src/components/BlueBox';

const BOT = () => (
  <Layout className="recruitment">
    <SEO title="BOT" />
    <div className="container pt-2">
      <PageTitle className="mb-2 mb-lg-8" title='Build - Operate - Transfer' />
      <h2 className="mb-3">How do you know you need B-O-T?</h2>
      <div className="row mb-lg-4">
        <BlueBox text={<span>Scale <strong>NOW</strong></span>} />
        <BlueBox text={<span>Too <strong>tough competition</strong> for top talents in your HQ location</span>} />
        <BlueBox text={<span><strong>Dependency on vendors</strong> is over the tolerable limit</span>} />
        <BlueBox text={<span>Expanding in your current location is <strong>too expensive</strong></span>} />
        <BlueBox text={<span>Healthy <strong>geographical diversification</strong> of teams is needed</span>} />
        <BlueBox text={<span>Missing <strong>partner for growth</strong></span>} />
      </div>
    </div>
    <div className="container pb-2 pb-lg-6">
      <h2 className="mb-3">How it works</h2>
      <p className="mb-3 text-justify">
        Build Operate Transfer allows you to fully concentrate on your business,
        while we make sure that the setup of your new subsidiary is absolutely smooth.
      </p>
      <p className="mb-3 text-justify">
        We will set up a white label entity and create the workplace for your new colleagues
        so that they feel part of your company from day one. You define the hiring needs and
        we make them happen. You have full visibility into the operational and financial
        aspects and if desired also right for audit. Of course, we enforce the implementation
        of your company rules and meet your security requirements. With the transit you acquire
        an autonomous and fully operational team that has already been working with you and your
        company for a certain time.
      </p>
      <p className="mb-5 text-justify">
        <strong>Our success is solely dependent on the success of the newly established team.</strong>
      </p>
      <div className="white-box p-lg-4">
        <h2 className="mb-1 mb-lg-3">Build</h2>
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className="white-box__list">
              <li>Clear definition of your needs</li>
              <li>Design of the all-in-one solution</li>
              <li>White label of your company</li>
              <li>Setting-up of the workspace for the new team</li>
              <li>Recruiting the right talents & Setting up the team according to your needs</li>
              <li>Scalable approach</li>
              <li>Management of all the local specifics (legal aspects, finance, labor code and employment standards, ...)</li>
              <li>HR, onboarding</li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 pl-lg-8">
            <StaticImage
              className="mt-4 mt-lg-0"
              src="../assets/images/bot/build.png"
              alt="Graphic"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
      <div className="white-box p-lg-4">
        <h2 className="mb-1 mb-lg-3">Operate</h2>
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className="white-box__list">
              <li>We work according to the HQ standards with all aspects (office design and layout, team’s daily routine, meeting security standards)</li>
              <li>Dealing with the day-to-day operations</li>
              <li>The team starts working on your project from day one. They are integral part of your team</li>
              <li>Full visibility into operations and financial flows</li>
              <li>Implementation of your internal policies</li>
              <li>Meet your security requirements</li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 pl-lg-8">
            <StaticImage
              className="mt-4 mt-lg-0"
              src="../assets/images/bot/operate.png"
              alt="Graphic"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
      <div className="white-box p-lg-4">
        <h2 className="mb-1 mb-lg-3">Transfer</h2>
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className="white-box__list">
              <li>You acquire fully operational team of targeted size and maturity</li>
              <li>Terms and conditions of the transition clearly defined</li>
              <li>Handover of the entity </li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 pl-lg-8">
            <StaticImage
              className="mt-4 mt-lg-0"
              src="../assets/images/bot/transfer.png"
              alt="Graphic"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default BOT;
